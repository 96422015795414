<template>
  <ul class="menu">
    <li class="first">用户中心</li>
    <li :class="`${path == 'my' ? 'current' : ''}`">
      <router-link to="/ucenter/my">个人资料</router-link>
    </li>
    <li :class="`${path == 'vip' ? 'current' : ''}`">
      <router-link to="/ucenter/vip">开通星级会员</router-link>
    </li>
    <li :class="`${path == 'wallet' ? 'current' : ''}`"><router-link to="/ucenter/wallet">我的钱包</router-link></li>
    <li :class="`${path == 'pay' ? 'current' : ''}`"><router-link to="/ucenter/pay">D币充值</router-link></li>
    <li :class="`${path == 'join' ? 'current' : ''}`"><router-link to="/ucenter/join">我的参与</router-link></li>
    <li :class="`${path == 'publish' ? 'current' : ''}`"><router-link to="/ucenter/publish">我的发布</router-link></li>
    <li :class="`${path == 'fav' ? 'current' : ''}`"><router-link to="/ucenter/fav">我的收藏</router-link></li>
    <li :class="`${path == 'help' ? 'current' : ''}`"><router-link to="/ucenter/help">帮助中心</router-link></li>
  </ul>
</template>
<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()
const path = computed(() => {
  return route.path.substring(route.path.lastIndexOf('/') + 1)
})
</script>
<style lang="scss" scoped>
.menu {
  padding: 15px;
  box-sizing: border-box;
  background: white;
  width: 260px;
  li.first{
    color: #666;
    font-weight: bold;
    background: #dddddd;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    &:hover{
      background: #dddddd;
    }
  }
  li {
    margin: 5px;
    padding: 10px;
    color: white;
    background: #b0daff;
    a {
      color: #666;
    }
    &:hover {
      background: #2683fa;

      a {
        color: white;
      }
    }
  }
  li.current {
    background: #2683fa;

    a {
      color: white;
    }
  }
}
</style>

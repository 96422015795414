<template>
  <div class="page-wrap">
    <div class="section-wrap">
      <div class="game-wrap">
        <div class="game">
          <div
            id="wheel"
            ref="wheelRef"
            style="
              border-radius: 50%;
              background-size: cover;
              position: relative;
            "
          >
            <div class="gifts">
              <div
                class="item"
                v-for="(item, index) in pageData.awardSet"
                :key="item.id"
                :style="`transform: rotate(${
                  (360 / pageData.awardSet.length) * (index + 1) +
                  180 / pageData.awardSet.length
                }deg);`"
              >
                <div
                  class="content"
                  :style="`transform: rotate(${
                    360 - 360 / pageData.awardSet.length
                  }deg);`"
                >
                  <span>{{ item.name }}</span>
                  <img
                    src="@/assets/imgs/zhuanpan_dou.png"
                    v-if="item.id != 1"
                  />
                  <span v-else style="display: inline-block; height: 26px">
                  </span>
                </div>
              </div>
            </div>
            <div
              id="pointer"
              style="
                width: 100px;
                height: 100px;
                border-radius: 50%;

                background-size: cover;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -75%);
              "
            ></div>
          </div>
          <div id="spin" @click="handleSpin"></div>
        </div>
        <div class="info">
          <h4>活动规则</h4>
          <div>1、注册帐号每天登录领取三次抽奖机会</div>
          <div>
            2、D币将分为10D币，20D币，50D币，150D币，200D币，250D币，300D币
          </div>
          <div>3、活动最终解释权归主办方所有</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Login from '@/components/Login'
import { ref, reactive } from 'vue'
import { Api } from '@/services/http'
import { ElMessage, ElMessageBox } from 'element-plus'
const wheelRef = ref(null)
const pageData = reactive({
  awardSet: [],
})
const startDeg = 0
const duration = 0
const speed = ref(3)
let isSpeedDown = false
let animateHandler
const ids = []

const handleSpin = async () => {
  const data = await Api.get(`/client-api/draw/start`)
  console.log('data', data)
  if (data && data.hit) {
    const hit = data.hit
    //334-651-276
    // { score:100,id:6}
    const find = pageData.awardSet.find(item => item.id == hit.id)
    spin2(find.degree, find)
  }
}
function spin2(degree, gift) {
  degree +=
    Math.random() *
    (360 / pageData.awardSet.length / 4) *
    (Math.random() > 0.5 ? 1 : -1)
  // requestAnimationFrame(()=>{console.info(1)})
  const MAXIMUM_SPEED = 0.48 // 最大速度
  const ACCELERATION_TIME = 2000 // 加速的时间
  const ACCELERATION_PER_MS = MAXIMUM_SPEED / ACCELERATION_TIME // 每毫秒的加速度
  const wheeldom = wheelRef.value
  class WheelSprite {
    constructor(gift) {
      this.gift = gift
      // paused 暂停
      // acceleration 加速, decelerate 减速
      this.status = 'paused'
      this.v = 0
      this.t0 = 0
      this.t2 = 0
      this._dist = 100
    }

    set dist(val) {
      wheeldom.style.transform = `rotate(${val}deg)  translate3d(0,0,0)`
      return (this._dist = val)
    }

    get dist() {
      return this._dist
    }

    async run() {
      if (this.status != 'paused') {
        throw new Error('转盘状态错误')
      }

      this.status = 'acceleration'
      this.t0 = new Date()

      let S = 0
      let dist = this.dist

      // 除非收到 decelerate 的状态，否则这个将会一直旋转
      while (this.status != 'decelerate') {
        await new Promise(resolve => requestAnimationFrame(resolve))

        // 执行的时间间隔，用来计算当前的位置
        const dif_t = new Date() - this.t0

        // 如果过了加速的时间段，则位移应该是加速的位移加上匀速的位移
        if (dif_t > ACCELERATION_TIME) {
          this.v = MAXIMUM_SPEED
          let s1 = (MAXIMUM_SPEED * ACCELERATION_TIME) / 2
          S = s1 + MAXIMUM_SPEED * (dif_t - ACCELERATION_TIME)
        } else {
          this.v = (dif_t / ACCELERATION_TIME) * MAXIMUM_SPEED
          S = (this.v * dif_t) / 2
        }

        this.dist = dist + S
      }
    }

    async stop(target) {
      if (this.status != 'acceleration') {
        throw new Error('转盘状态错误')
      }

      target = target % 360

      this.t2 = new Date()
      this.status = 'decelerate'

      // 标记匀减速前所有的路径总和
      let S = this.dist

      // target + 360 * 2 - S % 360 = MAXIMUM_SPEED / 2 * △t
      let t2Tot3 = (target + 360 * 2 - (S % 360)) / (MAXIMUM_SPEED / 2)
      this.t3 = this.t2 + t2Tot3

      const gift = this.gift
      while (1) {
        await new Promise(resolve => requestAnimationFrame(resolve))
        const dif_t = new Date() - this.t2

        // 考虑到当时间超出t3后，vn会变成负数，此处做一下限制
        let vn = Math.max(0, MAXIMUM_SPEED - (MAXIMUM_SPEED / t2Tot3) * dif_t)
        this.dist = S + ((vn + MAXIMUM_SPEED) * dif_t) / 2 //梯形公式

        // 当到达终点的时候， vn = 0
        if (vn == 0) {
          this.dist = target
          if (gift.id != 1) {
            ElMessageBox({
              type: 'success',
              title: '恭喜',
              message: `恭喜您，您获取${gift.score}个D币`,
            })
          } else {
            ElMessageBox({
              type: 'info',
              title: '消息',
              message: `${gift.name}`,
            })
          }

          break
        }
      }

      this.status = 'paused'
    }
  }
  const wheel = new WheelSprite(gift)
  wheel.run()

  setTimeout(() => {
    wheel.stop(degree)
  }, 5000)
}
function spin() {
  isSpeedDown = false
  speed.value = 3
  var wheel = wheelRef.value
  var pointer = document.getElementById('pointer')

  // 转盘转动动画
  wheel.style.webkitTransform = 'rotate(0deg)'
  wheel.style.MozTransform = 'rotate(0deg)'
  wheel.style.msTransform = 'rotate(0deg)'
  wheel.style.OTransform = 'rotate(0deg)'
  wheel.style.transform = 'rotate(0deg)'

  var degrees = (360 * Math.random()) | 0 // 随机度数

  // 指针旋转动画
  pointer.style.webkitTransform = 'rotate(' + degrees + 'deg)'
  pointer.style.MozTransform = 'rotate(' + degrees + 'deg)'
  pointer.style.msTransform = 'rotate(' + degrees + 'deg)'
  pointer.style.OTransform = 'rotate(' + degrees + 'deg)'
  pointer.style.transform = 'rotate(' + degrees + 'deg)'

  var animate2 = function () {}
  // 转盘持续旋转动画
  var animate = function () {
    degrees += speed.value
    if (!isSpeedDown) {
      speed.value += 0.1
      if (speed.value >= 15) {
        isSpeedDown = true
      }
    } else {
      speed.value -= 0.1
      if (speed.value <= 0) {
        speed.value = 0
        console.log('end...', degrees, speed.value)
        // ids.forEach(item => {
        //   window.cancelAnimationFrame(item)
        // })
        const len = pageData.awardSet.length

        const degree = degrees % 360

        let index = 0
        const awards = pageData.awardSet.concat().reverse()
        for (let i = 0; i < len; i++) {
          let currentDegree = awards[i].degree
          let maxDegree = awards[i].maxDegree
          if (degree >= currentDegree && degree <= maxDegree) {
            index = i
            break
          }
        }
        const gift = awards[index]

        console.log('degree', degree, 'index', index, gift)
        if (gift.score == 0) {
          ElMessageBox({
            type: 'info',
            title: '消息',
            message: `${gift.name}`,
          })
        } else {
          ElMessageBox({
            type: 'success',
            title: '恭喜',
            message: `恭喜您，您获取${gift.score}个D币`,
          })
        }

        window.cancelAnimationFrame(animateHandler)
        return
      }
    }

    wheel.style.webkitTransform = 'rotate(' + degrees + 'deg)'
    wheel.style.MozTransform = 'rotate(' + degrees + 'deg)'
    wheel.style.msTransform = 'rotate(' + degrees + 'deg)'
    wheel.style.OTransform = 'rotate(' + degrees + 'deg)'
    wheel.style.transform = 'rotate(' + degrees + 'deg)'

    animateHandler = window.requestAnimationFrame(animate)
    ids.push(animateHandler)

    // console.log('id---',animateHandler)
  }
  cancelAnimationFrame(animateHandler)
  animateHandler = window.requestAnimationFrame(animate)
  ids.push(animateHandler)
  console.log('id---', animateHandler)
}

const mounted = async () => {
  const gifs = await Api.get(`/client-api/draw/awardSet`)
  pageData.awardSet = gifs.awardSet
  pageData.awardSet = pageData.awardSet.map((item, index) => {
    let len = pageData.awardSet.length
    let per = 360 / len
    let degree = 360 - (360 / len) * index
    degree -= per / 2
    degree = degree % 360
    // degree = degree < 0 ? degree + 360 : degree
    return {
      ...item,
      degree,
      maxDegree: degree + per,
    }
  })
  const len = pageData.awardSet.length
  const degreeMap = Array.from({ length: len })
    .fill(0)
    .map((item, index) => {
      return (360 / len) * index - 360 / len
    })
  console.log('degreeMap', degreeMap)
}
mounted()
</script>
<style lang="scss" scoped>
.page-wrap {
  min-height: 500px;
  .game {
    width: 400px;
    margin: 0 auto;
    position: relative;
    border-radius: 100%;
    box-shadow: 1px 1px 13px rgba(212, 212, 212, 0.25);
    border: 3px solid #ff9300;
  }
  .info {
    text-align: left;
    width: 400px;
    margin: 0 auto;
  }
}
#wheel,
#pointer {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

#spin {
  position: absolute;
  z-index: 2;
  background: url('../assets/imgs/zhuanpan_btn.png');
  background-size: 100% 100%;
  width: 84px;
  height: 97px;
  top: 150px;
  left: 156px;
  cursor: pointer;
}

#wheel {
  background: url('../assets/imgs/zhuanpan_bg.png');
  width: 400px;
  height: 400px;
  position: relative;
  .gifts {
    .item {
      width: 50%;
      height: 50%;
      height: 200px;
      transform-origin: 200px 200px;
      position: absolute;
      .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        height: 100%;
        width: 100%;
        text-align: center;
        font-weight: bold;
        color: #e85e14;
      }
    }
    img {
      width: 20px;
      height: 20px;
      transform: rotate(45deg);
    }
  }
}
</style>

<template>
  <div>
    <div class="statistic-wrap">
      <el-row :gutter="20">
        <el-col :span="12">
          <div>
            <el-statistic
              group-separator=","
              :precision="2"
              :value="statistic.rechargeTotalAmount"
              title="累计充值（元）"
            ></el-statistic>
          </div>
        </el-col>
        <el-col :span="12">
          <div>
            <el-statistic title="D币(个)" :value="statistic.scoreBalance">
            </el-statistic>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="充值记录" name="first">
        <div v-if="payData.recharge">
          <div
            class="wallet-item"
            v-for="item in payData.recharge"
            :key="item.id"
          >
            <div
              class="wallet-item"
              v-for="item in payData.recharge"
              :key="item.id"
            >
              <div class="info">
                <div class="tit">{{ item.notes }}</div>
                <div class="em">
                  <span>变更前：{{ item.changeBefore }}</span
                  ><span>变更后：{{ item.changeAfter }}</span>
                  <span>操作时间：{{ item.createDate }}</span>
                </div>
              </div>
              <div class="price">-{{ item.changeAmount }} D币</div>
            </div>
          </div>
        </div>
        <el-empty
          description="空空如野"
          v-if="
            !payData.recharge ||
            (payData.recharge && payData.recharge.length <= 0)
          "
        ></el-empty>
        <div
          class="wrapper-page"
          v-if="payData.recharge && payData.recharge.length"
        >
          <el-pagination
            background
            layout="prev, pager, next"
            :total="payData.recharge_pagination.total"
            :page-size="10"
            @current-change="handlePage"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="消费记录" name="second">
        <div v-if="payData.charge">
          <div
            class="wallet-item"
            v-for="item in payData.charge"
            :key="item.id"
          >
            <div class="info">
              <div class="tit">{{ item.notes }}</div>
              <div class="em">
                <span>变更前：{{ item.changeBefore }}</span
                ><span>变更后：{{ item.changeAfter }}</span>
                <span>操作时间：{{ item.createDate }}</span>
              </div>
            </div>
            <div class="price">-{{ item.changeAmount }} D币</div>
          </div>
        </div>
        <el-empty
          description="空空如野"
          v-if="
            !payData.charge || (payData.charge && payData.charge.length <= 0)
          "
        ></el-empty>
        <div
          class="wrapper-page"
          v-if="payData.charge && payData.charge.length"
        >
          <el-pagination
            background
            layout="prev, pager, next"
            :total="payData.charge_pagination.total"
            :page-size="10"
            @current-change="handleChargePage"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script setup>
import { ref, reactive } from 'vue'
import qs from 'qs'
import { Api } from '@/services/http'
const activeName = ref('first')
const statistic = reactive({
  rechargeTotalAmount: 0,
  scoreBalance: 0,
})
const payData = reactive({
  recharge: [],
  recharge_pagination: { current: 1, size: 15, total: 1 },
  charge: [],
  charge_pagination: { current: 1, size: 15, total: 1 },
})
const handlePage = page => {
  payData.charge_paginatio.current = page
  loadReCharge()
}
const handleChargePage = page => {
  payData.charge_paginatio.current = page
  loadCharge()
}
const loadReCharge = async () => {
  const rechargeList = await Api.post(
    `/client-api/walletRecharge/list`,
    qs.stringify(payData.recharge_pagination)
  )
  payData.recharge = rechargeList.list
}
const loadCharge = async () => {
  const chargeList = await Api.post(
    `/client-api/walletChange/list`,
    qs.stringify(payData.charge_pagination)
  )
  payData.charge = chargeList.list
}
const mounted = async () => {
  const info = await Api.get(`/client-api/wallet/get`)
  statistic.rechargeTotalAmount = info.rechargeTotalAmount
  statistic.scoreBalance = info.scoreBalance
  console.log('info', info)

  await loadReCharge()
  await loadCharge()
  console.log('recharge', payData)
}
mounted()
</script>
<style lang="scss" scoped>
.statistic-wrap {
  background: #237ece;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  ::v-deep .el-statistic {
    .el-statistic__head {
      font-size: 16px;
      font-weight: bold;
      color: white;
    }
    .el-statistic__number {
      color: white;
    }
  }
}
.wallet-item {
  display: flex;
  text-align: left;
  justify-content: space-between;
  color: #ccc;
  background: #ffffff;
  border: 1px solid #efefef;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  .tit {
    font-size: 16px;
    font-weight: bold;
    color: #666;
  }
  .em span {
    margin-right: 10px;
  }
  .price {
    color: #f56c6c;
  }
}
</style>

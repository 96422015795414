<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="赛事" name="match">
        <div class="item" v-for="item in pageData.match || []" :key="item.id">
          <ItemNews :data="item" />
        </div>
        <el-empty
          description="空空如野"
          v-if="
            !pageData.match || (pageData.match && pageData.match.length <= 0)
          "
        ></el-empty>
      </el-tab-pane>
      <el-tab-pane label="找合伙" name="partnership"
        ><div
          class="item"
          v-for="item in pageData.partnership || []"
          :key="item.id"
        >
          <ItemProjectCoop :data="item" />
        </div>
        <el-empty
          description="空空如野"
          v-if="
            !pageData.partnership ||
            (pageData.partnership && pageData.partnership.length <= 0)
          "
        ></el-empty
      ></el-tab-pane>
      <el-tab-pane label="找项目" name="project"
        ><div
          class="item"
          v-for="item in pageData.project || []"
          :key="item.id"
        >
          <ItemProjectCoop :data="item" />
        </div>
        <el-empty
          description="空空如野"
          v-if="
            !pageData.project ||
            (pageData.project && pageData.project.length <= 0)
          "
        ></el-empty
      ></el-tab-pane>
    </el-tabs>
  </div>
</template>
<script setup>
import { ref, reactive } from 'vue'
import qs from 'qs'
import { Api } from '@/services/http'
import ItemProjectCoop from '@/components/ItemProjectCoop.vue'
import ItemProjectFund from '@/components/ItemProjectFund.vue'
import ItemNews from '@/components/ItemNews.vue'
import ItemMatch from '@/components/ItemMatch.vue'
const activeName = ref('match')
const pageData = reactive({
  match: null,
  match_pagination: { searchStr: '', current: 1, size: 15 },
  partnership: null,
  partnership_pagination: { searchStr: '', current: 1, size: 15 },
  project: null,
  project_pagination: { searchStr: '', current: 1, size: 15 },
})

function handleClick(tab) {
  const name = tab.paneName
  load(name)
}
async function load(name) {
  if (!pageData[name]) {
    const pagination = pageData[name + '_pagination'] ?? {}
    const data = await Api.get(
      `/client-api/myJoin/${name}`,
      qs.stringify(pagination)
    )
    pageData[name] = data
  }
}

async function mounted() {
  load('match')
}
mounted()
</script>

<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="使用规范" name="1">
        <HelpCollapse :data="pageData['1']"></HelpCollapse>
      </el-tab-pane>
      <el-tab-pane label="帐号个人信息" name="2">
        <HelpCollapse :data="pageData['2']"></HelpCollapse>
      </el-tab-pane>
      <el-tab-pane label="人脉信息" name="3">
        <HelpCollapse :data="pageData['3']"></HelpCollapse>
      </el-tab-pane>
      <el-tab-pane label="项目发布管理" name="4">
        <HelpCollapse :data="pageData['4']"></HelpCollapse>
      </el-tab-pane>
      <el-tab-pane label="其它问题" name="5">
        <HelpCollapse :data="pageData['5']"></HelpCollapse>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script setup>
import { ref, reactive } from 'vue'
import qs from 'qs'
import { Api } from '@/services/http'
import HelpCollapse from './HelpCollapse.vue' 
const activeName = ref('1')
const pageData = reactive({
  '1': null,
  '2': null,
  '3': null,
  '4':null
})

function handleClick(tab) {
  const name = tab.paneName
  load(name)
}
async function load(name) {
  if (!pageData[name]) {
    const params = {
      helpType:name,
      pageNo:1,
      pageSize:15,
      current:undefined,
      size:undefined
    }
    const data = await Api.post(`/client-api/helpCenter/list?${qs.stringify(params)}`)
    pageData[name] = data
  }
}

async function mounted() {
  load('1')
}
mounted()
</script>

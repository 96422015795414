<template>
  <el-collapse v-model="pageData.activeNames" @change="handleChange" :loading="pageData.loading">
    <el-collapse-item
      :title="item.title"
      :name="index"
      v-for="(item, index) in pageData.data"
      :key="item.id"
    >
      <div class="content">
        {{ pageData.details[index] }}
        <el-empty description="无结果" v-show="!pageData.details[index]" />
      </div>
    </el-collapse-item>
  </el-collapse>
  <el-empty description="无结果" v-show="!pageData.data|| !pageData.data.length" />
</template>
<script setup>
import { ref, reactive, defineProps, watch } from 'vue'
import { Api } from '@/services/http'
const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
})
const pageData = reactive({
  oldActiveNames: ['0'],
  activeNames: ['0'],
  details: {},
  loadingState:-1,
  data:null
})

watch( props, (newProps)=>{
  if( newProps.data ){
    pageData.data = [...newProps.data]
    load('0')
  }
})
const load = async name => {
  pageData.loadingState = 1
  if (props.data) {
    const item = props.data[name] || {}
    Api.get(`/client-api/helpCenter/get?id=${item.id}`).then(res => {
      console.log('res==', res)
      pageData.details[name] = res.content;
      pageData.loadingState =0
    })
  }
}
const handleChange = val => {
  const names = val
  let newName = ''
  for (let i = 0; i < names.length; i++) {
    if (pageData.oldActiveNames.indexOf(names[i]) == -1) {
      newName = names[i]
      break
    }
  }
  if (newName) {
    load(newName)
  }
  console.log('click', newName)
  pageData.oldActiveNames = names
}
if( props.data ){
  pageData.data = [...props.data]
}
load('0')
</script>

<style lang="scss" scoped>
.content{
  text-align: left;white-space: pre-wrap;
  font-size: 16px;;
}
::v-deep .el-collapse-item button{
  font-size: 18px;
  font-weight: bold;
}
</style>

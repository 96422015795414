<template>
  <div>
    
    <div style="text-align:left;display: none;">
      <el-input
      v-model="searchStr"
      style="width: 240px"
      placeholder="输入关键词"
    >
      <template #prefix>
        <el-icon class="el-input__icon"><search /></el-icon>
      </template>
    </el-input>
    </div>
  
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="找合伙" name="partnership">
        <div
          class="item"
          v-for="item in pageData.partnership || []"
          :key="item.id"
        >
          <ItemProjectCoop :data="item" />
        </div>
        <el-empty
          description="空空如野"
          v-if="
            !pageData.partnership ||
            (pageData.partnership && pageData.partnership.length <= 0)
          "
        ></el-empty>
      </el-tab-pane>
      <el-tab-pane label="找资源" name="resourceGruop"
        ><div
          class="item"
          v-for="item in pageData.resourceGruop || []"
          :key="item.id"
        >
          <ItemProjectCoop :data="item" />
        </div>
        <el-empty
          description="空空如野"
          v-if="
            !pageData.resourceGruop ||
            (pageData.resourceGruop && pageData.resourceGruop.length <= 0)
          "
        ></el-empty>
      </el-tab-pane>
      <el-tab-pane label="找项目" name="project"
        ><div
          class="item"
          v-for="item in pageData.project || []"
          :key="item.id"
        >
          <ItemProjectCoop :data="item" />
        </div>
        <el-empty
          description="空空如野"
          v-if="
            !pageData.project ||
            (pageData.project && pageData.project.length <= 0)
          "
        ></el-empty
      ></el-tab-pane>
      <el-tab-pane label="找资金" name="funder">
        <div class="item" v-for="item in pageData.funder || []" :key="item.id">
          <ItemProjectFund :data="item" />
        </div>
        <el-empty
          description="空空如野"
          v-if="
            !pageData.funder || (pageData.funder && pageData.funder.length <= 0)
          "
        ></el-empty>
      </el-tab-pane>
      <el-tab-pane label="消息" name="news"
        ><div class="item" v-for="item in pageData.news || []" :key="item.id">
          <ItemNews :data="item" />
        </div>
        <el-empty
          description="空空如野"
          v-if="!pageData.news || (pageData.news && pageData.news.length <= 0)"
        ></el-empty
      ></el-tab-pane>
      <el-tab-pane label="赛事" name="match"
        ><div class="item" v-for="item in pageData.match || []" :key="item.id">
          <ItemNews :data="item" />
        </div>
        <el-empty
          description="空空如野"
          v-if="
            !pageData.match || (pageData.match && pageData.match.length <= 0)
          "
        ></el-empty
      ></el-tab-pane>
      <el-tab-pane label="讲座" name="lecture"
        ><div
          class="item"
          v-for="item in pageData.lecture || []"
          :key="item.id"
        >
          <ItemNews :data="item" />
        </div>
        <el-empty
          description="空空如野"
          v-if="
            !pageData.lecture ||
            (pageData.lecture && pageData.lecture.length <= 0)
          "
        ></el-empty
      ></el-tab-pane>
    </el-tabs>
  </div>
</template>
<script setup>
import { ref, reactive } from 'vue'
import qs from 'qs'
import { Api } from '@/services/http'
import { Calendar, Search } from '@element-plus/icons-vue'
import ItemProjectCoop from '@/components/ItemProjectCoop.vue'
import ItemProjectFund from '@/components/ItemProjectFund.vue'
import ItemNews from '@/components/ItemNews.vue'
import ItemMatch from '@/components/ItemMatch.vue'
const searchStr = ref('')
const activeName = ref('partnership')
const pageData = reactive({
  partnership: null,
  partnership_pagination: { searchStr: '', current: 1, size: 15 },
  resourceGruop: null,
  resourceGruop_pagination: { searchStr: '', current: 1, size: 15 },
  project: null,
  project_pagination: { searchStr: '', current: 1, size: 15 },
  funder: null,
  funder_pagination: { searchStr: '', current: 1, size: 15 },
  news: null,
  news_pagination: { searchStr: '', current: 1, size: 15 },
  match: null,
  match_pagination: { searchStr: '', current: 1, size: 15 },
  lecture: null,
  lecture_pagination: { searchStr: '', current: 1, size: 15 },
})

function handleClick(tab) {
  const name = tab.paneName
  load(name)
}
async function load(name) {
  if (!pageData[name]) {
    const pagination = pageData[name + '_pagination'] ?? {}
    const data = await Api.get(
      `/client-api/myPublish/${name}`,qs.stringify(pagination)
    )
    pageData[name] = data
  }
}

async function mounted() {
  load('match')
}
mounted()
</script>
